/* =================== animated waves in the hero section =================== */
.hero-section-root {
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 93vh;
}

.floating-whatsapp-contact {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #293845;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  z-index: 1006;
}

.floating-whatsapp-contact .whatsapp-icon {
  width: 40px;
  height: 40px;
}

@media (max-width: 850px) {
  .hero-section-root {
    min-height: 60vh;
  }
}

@media (max-width: 768px) {
  .hero-section-root {
    min-height: 75vh;
  }
}

@media (max-width: 415px) {
  .hero-section-root {
    min-height: 100vh;
  }
}

/* @media (max-width: 400px) {
  .hero-section-root {
    min-height: 100vh;
  }
} */

.ocean {
  height: 5%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #293845;
}

.wave {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: url(../../assets/wave.png);
  background-size: 1000px 100px;
}

.wave.wave1 {
  animation: animate 30s linear infinite, swell 7s ease -1.25s infinite;
  z-index: 1000;
  opacity: 1;
  animation-delay: 0s;
  bottom: 0;
}

.wave.wave2 {
  animation: animate2 15s linear infinite, swell 7s ease -1.25s infinite;
  z-index: 999;
  opacity: 0.5;
  animation-delay: -5s;
  bottom: 10px;
}

.wave.wave3 {
  animation: animate 30s linear infinite, swell 7s ease -1.25s infinite;
  z-index: 998;
  opacity: 0.2;
  animation-delay: -2s;
  bottom: 15;
}

.wave.wave4 {
  animation: animate2 5s linear infinite, swell 7s ease -1.25s infinite;
  z-index: 997;
  opacity: 0.7;
  animation-delay: -5s;
  bottom: 20px;
}

@keyframes animate {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: 1000px;
  }
}

@keyframes animate2 {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: -1000px;
  }
}

@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -25px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}

/* =================== floating download button =================== */
.floating-btn {
  position: fixed;
  top: 46vh;
  /* top: 100px; */
  right: -30px;
  /* background: red; */
  min-width: 60px;
  height: 60px;
  border-radius: 12px 0 0 12px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  z-index: 1001;
  /* opacity: 0.1; */
}

.floating-btn:hover {
  right: 0;
  border-radius: 100px 0 0 100px;
  padding: 0 8px;
  /* opacity: 1; */
}

/* (max-width: 768px), */
@media screen and (hover: none) {
  .floating-btn {
    display: none;
  }
}

.sliding-arrows-container {
  position: relative;
  height: 100%;
}

.sliding-arrows {
  position: absolute;
  display: flex;
  left: 10px;
  top: 44%;
  transform: translate(-7%, -7%);
}

.sliding-arrows-container:hover .sliding-arrows {
  display: none;
}

.sliding-arrows span {
  display: block;
  width: 6px;
  height: 6px;
  border-left: 2px solid #293845;
  border-bottom: 2px solid #293845;
  transform: rotate(45deg);
  /* margin: -15px; */
  animation: animate 2s infinite;
}

.sliding-arrows span:nth-child(2) {
  animation-delay: -0.2s;
}

.sliding-arrows span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translateY(-4px, -4px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translateY(4px, 4px);
  }
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.sliding-arrows-container .icon-container {
  display: none;
}

.sliding-arrows-container:hover .icon-container {
  display: flex;
}
