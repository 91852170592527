.flip-card-container {
  position: relative;
  padding: 60px 0;
}

.flip-card-container .flip-card {
  position: relative;
  height: 200px;
}

.flip-card-container .flip-card .face {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: 0.5s;
  border-radius: 10px;
}

.flip-card-container .flip-card .face.face1 {
  background-color: #293845;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transform-origin: bottom;
  text-align: center;
}

/* .flip-card-container .flip-card .face.face1 .icon {
  width: 100px;
} */

.flip-card-container .flip-card .face.face1 .title {
  color: #fff;
  margin: 10px 0 0;
  text-align: center;
  /* font-size: 1.5em; */
}

.flip-card-container .flip-card:hover .face.face1 {
  transform: translateY(-100%) rotateX(90deg);
  background-color: #03dd74;
}

.flip-card-container .flip-card .face.face2 {
  background-color: #293845;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  transform-origin: top;
  transform: translateY(100%) rotateX(90deg);
}

.flip-card-container .flip-card:hover .face.face2 {
  transform: translateY(0) rotateX(0deg);
}
