.auth-container.rtl-dir:before {
  /* right: -55%; */
  right: -25%;
}

.auth-container.sign-up-mode.rtl-dir:before {
  /* right: -65%; */
  right: -35%;
}

.signin-signup.rtl-dir {
  transform: translate(50%, -50%);
  right: 75%;
}

.right-panel.rtl-dir .image,
.right-panel.rtl-dir .content {
  transform: translateX(-800px);
}

/* ANIMATION */
.auth-container.sign-up-mode.rtl-dir:before {
  transform: translate(-100%, -50%);
  /* left: 52%; */
}

.auth-container.sign-up-mode.rtl-dir .left-panel .image,
.auth-container.sign-up-mode.rtl-dir .left-panel .image-container,
.auth-container.sign-up-mode.rtl-dir .left-panel .content {
  transform: translateX(900px);
}

.auth-container.sign-up-mode.rtl-dir .signin-signup {
  right: 25%;
}

@media (min-width: 1000px) and (min-height: 700px) {
  .auth-container.rtl-dir:before {
    transform: translate(28%, -50%);
  }

  .auth-container.sign-up-mode.rtl-dir:before {
    transform: translate(-74%, -50%);
  }
}

@media (max-width: 912px) and (min-height: 950px) {
  .auth-container.rtl-dir:before {
    transform: translate(55%, -50%);
  }

  .auth-container.sign-up-mode.rtl-dir:before {
    transform: translate(-35%, -50%);
  }
}

@media (min-width: 1000px) and (max-height: 800px) {
  .auth-container.rtl-dir:before {
    transform: translate(45%, -50%);
  }

  .auth-container.sign-up-mode.rtl-dir:before {
    transform: translate(-55%, -50%);
  }
}

@media (min-width: 1000px) and (max-height: 600px) {
  .auth-container.rtl-dir:before {
    transform: translate(58%, -50%);
  }

  .auth-container.sign-up-mode.rtl-dir:before {
    transform: translate(-45%, -50%);
  }
}

@media (max-width: 870px) {
  .signin-signup.rtl-dir {
    transform: translate(50%, -100%);
  }

  .signin-signup.rtl-dir,
  .auth-container.sign-up-mode.rtl-dir .signin-signup {
    right: 50%;
  }

  .auth-container.rtl-dir:before {
    transform: translateX(50%);
    right: 36%;
    left: initial;
  }

  .auth-container.sign-up-mode.rtl-dir:before {
    transform: translate(-10%, 100%);
    /* bottom: 32%; */
    left: initial;
  }

  .auth-container.sign-up-mode.rtl-dir .signin-signup {
    transform: translate(50%, 0);
  }
}

@media (max-width: 570px) {
  .auth-container.rtl-dir:before {
    right: 50%;
  }

  .auth-container.sign-up-mode.rtl-dir:before {
    bottom: 30%;
    right: -100%;
  }
}

@media (max-width: 300px) {
  .auth-container.sign-up-mode.rtl-dir:before {
    transform: translate(21%, 103%);
    /* bottom: 30%;
    right: -120%; */
  }
}

.animated-trakib-container.rtl-dir .animated-trakib {
  transform: translate(12%, -55%);
}
