/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap"); */

.auth-container {
  position: relative;
  width: 100%;
  background-color: #293845;
  min-height: 100vh;
  overflow: hidden;
}

.forms-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.signin-signup {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 75%;
  width: 50%;
  transition: 1s 0.7s ease-in-out;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 5;
}

.forms-container form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0rem 5rem;
  transition: all 0.2s 0.7s;
  overflow: hidden;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

form.sign-up-form {
  opacity: 0;
  z-index: 1;
}

form.sign-in-form {
  z-index: 2;
}

.auth-container .title {
  font-size: 2.2rem;
  color: #03dd74;
  margin-bottom: 10px;
}

.social-text {
  padding: 0.7rem 0;
  font-size: 1rem;
  color: #fff;
}

.auth-container .social-media {
  display: flex;
  justify-content: center;
}

.auth-container .social-icon {
  height: 46px;
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.45rem;
  color: #fff;
  border-radius: 50%;
  border: 1px solid #333;
  text-decoration: none;
  font-size: 1.1rem;
  transition: 0.3s;
}

.auth-container .social-icon:hover {
  color: #03dd74;
  border-color: #03dd74;
}

.auth-container .btn {
  width: 150px;
  background-color: #03dd74;
  border: none;
  outline: none;
  height: 49px;
  border-radius: 49px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
  cursor: pointer;
  transition: 0.5s;
  font-family: Almarai;
}

.auth-container .btn:hover {
  background-color: #09ef7f;
}

.auth-container .disabled-btn {
  opacity: 0.5;
  cursor: not-allowed;
}

.panels-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.auth-container:before {
  content: "";
  position: absolute;
  height: 2000px;
  width: 2000px;
  top: -10%;
  right: 48%;
  transform: translateY(-50%);
  background-image: linear-gradient(-45deg, #03dd74 0%, #049f54 100%);
  transition: 1.8s ease-in-out;
  border-radius: 50%;
  z-index: 6;
}

.auth-container .image,
.auth-container .image-container {
  width: 100%;
  transition: transform 1.1s ease-in-out;
  transition-delay: 0.4s;
}

.panel {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  text-align: center;
  z-index: 6;
}

.left-panel {
  pointer-events: all;
  padding: 3rem 17% 2rem 12%;
  /* border: 1px solid red; */
  /* align-items: flex-start; */
}

.left-panel .image-container {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  /* justify-content: space-between; */

  width: 100%;
  /* border: 1px solid red; */
}

.left-panel .image-container .image {
  width: 100%;
  /* border: 1px solid red; */
}

.left-panel .image {
  width: 100%;
}

.right-panel {
  pointer-events: none;
  padding: 3rem 12% 2rem 17%;
}

.panel .content {
  color: #fff;
  transition: transform 0.9s ease-in-out;
  transition-delay: 0.6s;
}

.panel h3 {
  font-weight: 600;
  line-height: 1;
  font-size: 1.5rem;
}

.panel p {
  font-size: 0.95rem;
  padding: 0.7rem 0;
}

.btn.transparent {
  margin: 0;
  background: none;
  border: 2px solid #fff;
  width: 130px;
  height: 41px;
  font-weight: 600;
  font-size: 0.8rem;
}

.right-panel .image,
.right-panel .content {
  transform: translateX(800px);
}

/* ANIMATION */
.auth-container.sign-up-mode:before {
  transform: translate(100%, -50%);
  right: 52%; /* i think this property is useless*/
}

.auth-container.sign-up-mode .left-panel .image,
.auth-container.sign-up-mode .left-panel .image-container,
.auth-container.sign-up-mode .left-panel .content {
  transform: translateX(-800px);
}

.auth-container.sign-up-mode .signin-signup {
  left: 25%;
}

.auth-container.sign-up-mode form.sign-up-form {
  opacity: 1;
  z-index: 2;
}

.auth-container.sign-up-mode form.sign-in-form {
  opacity: 0;
  z-index: 1;
}

.auth-container.sign-up-mode .right-panel .image,
.auth-container.sign-up-mode .right-panel .content {
  transform: translateX(0%);
}

.auth-container.sign-up-mode .left-panel {
  pointer-events: none;
}

.auth-container.sign-up-mode .right-panel {
  pointer-events: all;
}

@media (max-width: 912px) and (min-height: 950px) {
  .auth-container:before {
    transform: translate(5%, -50%);
  }

  .auth-container.sign-up-mode:before {
    transform: translate(95%, -50%);
  }

  .forms-container form {
    padding: 0 1.5rem;
  }
}

@media (min-width: 1000px) and (max-height: 600px) {
  .auth-container {
    height: 120vh;
  }

  .forms-container form {
    padding: 0 1.5rem;
  }
}

@media (max-width: 870px) {
  .auth-container {
    min-height: 800px;
    height: 105vh;
  }
  .signin-signup {
    width: 100%;
    top: 95%;
    transform: translate(-50%, -100%);
    transition: 1s 0.8s ease-in-out;
  }

  .signin-signup,
  .auth-container.sign-up-mode .signin-signup {
    left: 50%;
  }

  .panels-container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
  }

  .panel {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 2.5rem 8%;
    grid-column: 1 / 2;
  }

  .right-panel {
    grid-row: 3 / 4;
  }

  .left-panel {
    grid-row: 1 / 2;
  }

  .auth-container .image,
  .auth-container .animated-trakib-container {
    width: 200px;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.6s;
  }

  .panel .content {
    padding-right: 15%;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.8s;
  }

  .panel h3 {
    font-size: 1.2rem;
  }

  .panel p {
    font-size: 0.7rem;
    padding: 0.5rem 0;
  }

  .btn.transparent {
    width: 110px;
    height: 35px;
    font-size: 0.7rem;
  }

  .auth-container:before {
    width: 1500px;
    height: 1500px;
    transform: translateX(-50%);
    left: 30%;
    bottom: 68%;
    right: initial;
    top: initial;
    transition: 2s ease-in-out;
  }

  .auth-container.sign-up-mode:before {
    transform: translate(-50%, 100%);
    bottom: 32%;
    right: initial;
  }

  /* .auth-container.sign-up-mode .left-panel .image, */
  .auth-container.sign-up-mode .left-panel .image-container,
  .auth-container.sign-up-mode .left-panel .content {
    transform: translateY(-300px);
  }

  .auth-container.sign-up-mode .right-panel .image,
  .auth-container.sign-up-mode .right-panel .content {
    transform: translateY(0px);
  }

  .right-panel .image,
  .right-panel .content {
    transform: translateY(300px);
  }

  .auth-container.sign-up-mode .signin-signup {
    top: 5%;
    transform: translate(-50%, 0);
  }
}

@media (max-width: 570px) {
  .forms-container form {
    padding: 0 1.5rem;
  }

  .auth-container .image,
  .left-panel .image-container {
    display: none;
  }
  .panel .content {
    padding: 0.5rem 1rem;
  }
  .auth-container {
    padding: 1.5rem;
  }

  .auth-container:before {
    bottom: 72%;
    left: 50%;
  }

  .auth-container.sign-up-mode:before {
    bottom: 28%;
    left: 50%;
  }

  .auth-container.sign-up-mode {
    height: 123vh;
  }
}

@media (max-width: 680px) and (min-height: 595px) {
  .auth-container.sign-up-mode {
    height: 150vh;
  }
}

@media (max-width: 550px) and (min-height: 700px) {
  .auth-container.sign-up-mode {
    height: 152vh;
  }
}

@media (max-width: 450px) and (min-height: 800px) {
  .auth-container.sign-up-mode {
    height: 120vh;
  }
}

@media (max-width: 375px) and (max-height: 700px) {
  .auth-container.sign-up-mode {
    height: 160vh;
  }
}

@media (max-width: 360px) {
  .auth-container.sign-up-mode {
    height: 140vh;
  }
}

@media (max-width: 300px) {
  .auth-container.sign-up-mode {
    height: 152vh;
  }
}

/* animated trakib */
.animated-trakib-container {
  position: relative;
  /* background-color: #4481eb; */
}

.animated-trakib-container .animated-trakib {
  position: absolute;
  color: #fff;
  font-size: 8.2vw;
  /* font-size: 6em; */
  transform: translate(-8%, -55%);
}

.animated-trakib-container .animated-trakib:nth-child(1) {
  color: transparent;
  -webkit-text-stroke: 2px #049f54;
}

.animated-trakib-container .animated-trakib:nth-child(2) {
  color: #03dd74;
  animation: animated-trakib 4s ease-in-out infinite;
}

@keyframes animated-trakib {
  0%,
  100% {
    clip-path: polygon(
      0% 45%,
      15% 44%,
      32% 50%,
      54% 60%,
      70% 61%,
      84% 59%,
      100% 52%,
      100% 100%,
      0% 100%
    );
  }

  50% {
    clip-path: polygon(
      0% 60%,
      16% 65%,
      34% 66%,
      51% 62%,
      67% 50%,
      84% 45%,
      100% 46%,
      100% 100%,
      0% 100%
    );
  }
}

@media (max-width: 600px) {
  .animated-trakib-container .animated-trakib {
    transform: translate(-8%, -59.6%);
  }
}
